import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements AfterViewInit{

  public backdropColor = input('0, 0, 0');
  public backdropOpacity = input(50);

  @ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;

  constructor() { }

  ngAfterViewInit() {
    this.dialog.nativeElement.style.setProperty('--backdrop-color', `rgba(${this.backdropColor()}, ${this.backdropOpacity() < 100 ? this.backdropOpacity() / 100 : 1})`)
  }

  openDialog() {
    this.dialog.nativeElement.showModal();
  }

  closeDialog() {
    this.dialog.nativeElement.close();
  }

}
